import { IApi } from "./IApi";

export class Api implements IApi {
    constructor(url: string) {
        this.url = this.cleanUrl(url);
    }

    private cleanUrl(url: string): string {
        if (url.endsWith('/')) {
            return url.substring(0, url.length - 1);
        }
        return url;
    }

    url: string;

    getUrl(path: string): string {
        return this.url + this.cleanPath(path);
    }

    private cleanPath(path: string): string {
        if (!path.startsWith('/')) {
            return '/' + path;
        }
        return path;
    }
}
