export enum DeviceType {
    Desktop = 0,
    Tablet = 1,
    Mobile = 2,
    Crawler = 3,
    ApprovedCrawler = 4,
    Process = 5,
    Console = 6,
    IOS = 7,
    Android = 8,
    Windows = 9,
    Mac = 10,
    IPhone = 11,
    IPad = 12,
    Service = 13,
    AndroidTablet = 14,
    AndroidTV = 0xF,
    AndroidWatch = 0x10,
    IOSTV = 17,
    IOSWatch = 18,
    UWP = 19,
    UWPPhone = 20,
    UWPTablet = 21,
    UWPDesktop = 22,
    UWPTV = 23,
    UWPWatch = 24,
    Unknown = 99,
}