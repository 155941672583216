import { IClaim } from "./IClaim";

export class Claim implements IClaim {
    constructor(type: string, val: string, props: { [name: string]: string }) {
        this.type = type;
        this.val = val;
        this.props = props;
    }

    type: string;
    val: string;
    props: { [name: string]: string }
;

}
