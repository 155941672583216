import { IUserInfo } from "./IUserInfo";

export class UserInfo implements IUserInfo {
    constructor(data: any) {
        this.id = data.id;
        this.username = data.username;
        this.accountId = data.accountId;
        this.accountNumber = data.accountNum;
        this.accountName = data.accountName;
        this.csrId = data.csrId;
        this.csrName = data.csrName;
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.screen = data.screen;
        this.email = data.email;
        this.phone = data.phone;
        this.mobile = data.mobile;

    }

    id: string;
    username: string;
    accountId: string;
    accountNumber: number;
    accountName: string;

    csrId: string;
    csrName: string;

    firstName: string;
    lastName: string;
    screen: string;
    email: string;
    phone: string;
    mobile: string;
}
