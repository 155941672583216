export enum RelationshipType {
    Unknown = 0,
    User = 1,
    Location = 2,
    Account = 3,
    Rating = 4,
    Invoice = 5,
    Email = 6,
    Promotion = 8,
    Banner = 9,
    Subscription = 10,
    Payment = 11,
    Repair = 12,
    Newsletter = 13,
    Route = 14,
    Receipt = 15,
    ApiKey = 16,
    Advert = 17
}
