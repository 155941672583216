import { IHistoryManager } from "./AdminHistory/IHistoryManager";
import { IAdvertProvider } from "./Adverts/IAdvertProvider";
import { IAddressValidator } from "./Authentication/IAddressValidator";
import { IApiKeyValidator } from "./Authentication/IApiKeyValidator";
import { IAuthenticator } from "./Authentication/IAuthenticator";
import { IAnalyticProcessor } from "./Analytics/IAnalyticProcessor";
import { IDataLogger } from "./Logging/IDataLogger";
import { IDataProtectionService } from "./DataProtection/IDataProtectionService";
import { IEventTrigger } from "./Events/IEventTrigger";
import { ILookupService } from "./Lookup/ILookupService";
import { IMessageSender } from "./Communications/IMessageSender";
import { IRelationshipService } from "./Relationships/IRelationshipService";
import { ISequencer } from "./Sequences/ISequencer";
import { ISessionProvider } from "./Sessions/ISessionProvider";
import { ITaskCreator } from "./Tasks/ITaskCreator";
import { IUserContext } from "./IUserContext";
import { ICaptchaService } from "./Throttling/ICaptchaService";
import { ICountryLookup } from "./Throttling/ICountryLookup";
import { IRestrictionManager } from "./Throttling/IRestrictionManager";
import { IThrottler } from "./Throttling/IThrottler";
import { IGeocoder } from "./Geocoding/IGeocoder";

export default function (context: IUserContext): void {
    context.registerService({
        name: 'IHistoryManager',
        activator: () => {
            return import(/* webpackChunkName: "HistoryManager" */ './AdminHistory/HistoryManager')
                .then((svc) => {
                    return new svc.HistoryManager(context) as IHistoryManager;
                });
        }
    });

    context.registerService({
        name: 'IAdvertProvider',
        activator: () => {
            return import(/* webpackChunkName: "AdvertProvider" */ './Adverts/AdvertProvider')
                .then((svc) => {
                    return new svc.AdvertProvider(context) as IAdvertProvider;
                });
        }
    });

    context.registerService({
        name: 'IAnalyticProcessor',
        activator: () => {
            return import(/* webpackChunkName: "AnalyticProcessor" */ './Analytics/AnalyticProcessor')
                .then((svc) => {
                    return new svc.AnalyticProcessor(context) as IAnalyticProcessor;
                });
        }
    });

    context.registerService({
        name: 'IAddressValidator',
        activator: () => {
            return import(/* webpackChunkName: "AddressValidator" */ './Authentication/AddressValidator')
                .then((svc) => {
                    return new svc.AddressValidator(context) as IAddressValidator;
                });
        }
    });

     context.registerService({
        name: 'IApiKeyValidator',
        activator: () => {
            return import(/* webpackChunkName: "ApiKeyValidator" */ './Authentication/ApiKeyValidator')
                .then((svc) => {
                    return new svc.ApiKeyValidator(context) as IApiKeyValidator;
                });
        }
    });

    context.registerService({
        name: 'IAuthenticator',
        activator: () => {
            return import(/* webpackChunkName: "Authenticator" */ './Authentication/Authenticator')
                .then((svc) => {
                    return new svc.Authenticator(context) as IAuthenticator;
                });
        }
    });

    context.registerService({
        name: 'IDataLogger',
        activator: () => {
            return import(/* webpackChunkName: "DataLogger" */ './Logging/DataLogger')
                .then((svc) => {
                    return new svc.DataLogger(context) as IDataLogger;
                });
        }
    });

    context.registerService({
        name: 'IDataProtectionService',
        activator: () => {
            return import(/* webpackChunkName: "DataProtectionService" */ './DataProtection/DataProtectionService')
                .then((svc) => {
                    return new svc.DataProtectionService(context) as IDataProtectionService;
                });
        }
    });

    context.registerService({
        name: 'IEventTrigger',
        activator: () => {
            return import(/* webpackChunkName: "EventTrigger" */ './Events/EventTrigger')
                .then((svc) => {
                    return new svc.EventTrigger(context) as IEventTrigger;
                });
        }
    });

    context.registerService({
        name: 'IGeocoder',
        activator: () => {
            return import(/* webpackChunkName: "Geocoder" */ './Geocoding/Geocoder')
                .then((svc) => {
                    return new svc.Geocoder(context) as IGeocoder;
                });
        }
    });

    context.registerService({
        name: 'IRelationshipService',
        activator: () => {
            return import(/* webpackChunkName: "RelationshipService" */ './Relationships/RelationshipService')
                .then((svc) => {
                    return new svc.RelationshipService(context) as IRelationshipService;
                });
        }
    });

    context.registerService({
        name: 'ISequencer',
        activator: () => {
            return import(/* webpackChunkName: "Sequencer" */ './Sequences/Sequencer')
                .then((svc) => {
                    return new svc.Sequencer(context) as ISequencer;
                });
        }
    });

    context.registerService({
        name: 'ISessionProvider',
        activator: () => {
            return import(/* webpackChunkName: "SessionProvider" */ './Sessions/SessionProvider')
                .then((svc) => {
                    return new svc.SessionProvider(context) as ISessionProvider;
                });
        }
    });

    context.registerService({
        name: 'ILookupService',
        activator: () => {
            return import(/* webpackChunkName: "LookupService" */ './Lookup/LookupService')
                .then((svc) => {
                    return new svc.LookupService(context) as ILookupService;
                });
        }
    });

    context.registerService({
        name: 'IMessageSender',
        activator: () => {
            return import(/* webpackChunkName: "MessageSender" */ './Communications/MessageSender')
                .then((svc) => {
                    return new svc.MessageSender(context) as IMessageSender;
                });
        }
    });

    context.registerService({
        name: 'ITaskCreator',
        activator: () => {
            return import(/* webpackChunkName: "TaskCreator" */ './Tasks/TaskCreator')
                .then((svc) => {
                    return new svc.TaskCreator(context) as ITaskCreator;
                });
        }
    });

    context.registerService({
        name: 'ICaptchaService',
        activator: () => {
            return import(/* webpackChunkName: "CaptchaService" */ './Throttling/CaptchaService')
                .then((svc) => {
                    return new svc.CaptchaService(context) as ICaptchaService;
                });
        }
    });

    context.registerService({
        name: 'ICountryLookup',
        activator: () => {
            return import(/* webpackChunkName: "CountryLookup" */ './Throttling/CountryLookup')
                .then((svc) => {
                    return new svc.CountryLookup(context) as ICountryLookup;
                });
        }
    });

    context.registerService({
        name: 'IRestrictionManager',
        activator: () => {
            return import(/* webpackChunkName: "RestrictionManager" */ './Throttling/RestrictionManager')
                .then((svc) => {
                    return new svc.RestrictionManager(context) as IRestrictionManager;
                });
        }
    });

    context.registerService({
        name: 'IThrottler',
        activator: () => {
            return import(/* webpackChunkName: "Throttler" */ './Throttling/Throttler')
                .then((svc) => {
                    return new svc.Throttler(context) as IThrottler;
                });
        }
    });
}
