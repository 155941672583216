import { IEventBus } from "./IEventBus";
import * as emitter from 'tiny-emitter/instance.js';

export class EventBus implements IEventBus {
    emit(event: string, ...args: any[]): IEventBus {
        (emitter as any).emit(event, ...args);
        return this;
    }
    off(event?: string | string[] | undefined, callback?: Function | undefined): IEventBus {
        (emitter as any).off(event, callback);
        return this;
    }

    on(event: string | string[], callback: Function): IEventBus {
        (emitter as any).on(event, callback);
        return this;
    }

    once(event: string | string[], callback: Function): IEventBus {
        (emitter as any).once(event, callback);
        return this;
    }
}