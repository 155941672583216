import { IUserContext } from "../IUserContext";

import { IClaim } from "./IClaim";
import { IUserInfo } from "./IUserInfo";
import { IApi } from "./IApi";
import { IServiceActivator } from "./IServiceActivator";

import { Claim } from "./Claim";
import { UserInfo } from "./UserInfo";
import { Api } from "./Api";

import { EventBus } from "./EventBus";
import { IEventBus } from "./IEventBus";

import { IApiManager } from "../IApiManager";
import { HttpMethod } from "../ApiManager/HttpMethod";
import { IInitializer } from "../IInitializer";
import { ApiManager } from "../ApiManager/ApiManager";

const systems_service = 'systems';
const profile_path = '/systems/profile-image';

export class UserContext implements IUserContext {
    constructor(
        antiforgery: { 'field': string, 'header': string, 'token': string },
        data?: string | null) {

        this.antiforgeryToken = antiforgery;

        if (data && data !== null) {
            var contextData = JSON.parse(data);
            this.contextId = contextData.id;
            this.instance = contextData.instance;
            this.application = contextData.app;
            this.system = contextData.sys;
            this.address = contextData.add;
            this.authToken = contextData.auth;
            this.deviceId = contextData.did;
            this.settings = contextData.settings;
            this.claims = this.getClaims(contextData.claims);
            this.user = this.getUserInfo(contextData.user);
            this.apis = this.getApis(contextData.services);
        } else {
            this.contextId = 'new guid';
            this.instance = '';
            this.application = '';
            this.system = 'truckdown';
            this.address = '';
            this.authToken = '';
            this.deviceId = '';
            this.settings = {};
            this.claims = [];
            this.user = null;
            this.apis = {};
        }

        this.profileImagePath = this.getProfileImagePath();
        this.apiManager = new ApiManager(this);
    }

    private getProfileImagePath(): string {
        var service = this.getApi(systems_service);
        if (service) {
            return service.getUrl(profile_path);
        }

        return systems_service + profile_path;
    }

    getApi(name: string): IApi | undefined {
        var service = this.apis[name];
        if (service) {
            return service;
        }
        return this.apis['api'];
    }

    private getClaims(claims: any): IClaim[] {
        return (claims as any[]).map(c => new Claim(c.type, c.val, c.props));
    }

    private getUserInfo(info: any): IUserInfo | null {
        if (info && info !== null) {
            return new UserInfo(info);
        }
        return null;
    }

    private getApis(info: any): { [name: string]: IApi; } {
        if (info && info !== null) {
            var services: { [name: string]: IApi; } = {};
            if (Array.isArray(info)) {
                let arr = info as Array<any>;
                for (let i = 0; i < arr.length; i++) {
                    services[arr[i].key] = new Api(arr[i].value.url);
                }
            } else {
                for (let k of Object.keys(info)) {
                    services[k] = new Api(info[k].url);
                }
            }
            return services;
        }
        return {};
    }

    contextId: string;
    instance: string;
    application: string;
    system: string;
    address: string;

    authToken: string;
    deviceId: string;

    settings: { [name: string]: string; };

    user: IUserInfo | null;
    claims: IClaim[];

    apiManager: IApiManager;

    get isAuthenticated(): boolean {
        return this.user !== null;
    }

    isInRole(role: string): boolean {
        return this.hasClaim('role', role);
    }

    hasClaim(claim: string, value?: string): boolean {
        return this.claims.some(c => c.type == claim && (!value || c.val == value));
    }

    getClaimValue(type: string): string | null {
        var claims = this.claims.filter(c => c.type === type).map(c => c.val);
        if (claims.length > 0) {
            return claims.join(',');
        }
        return null;
    }

    apis: { [name: string]: IApi; };

    get services(): string[] {
        return this.activators.map(a => a.name as string);
    }

    getService<T>(name: string): Promise<T> {
        for (let i = this.activators.length - 1; i >= 0; i--) {
            let activator = this.activators[i] as IServiceActivator<T>;
            if (activator.name == name) {
                return activator.activator();
            }
        }

        return new Promise<T>((val) => {
            var reason = 'Service ' + name + ' is not registered.';
            console.error(reason);
            throw reason;
        });
    }

    async getServices<T>(name: string): Promise<T[]> {
        var services: T[] = [];

        let activators = (this.activators as IServiceActivator<T>[])
            .filter((a) => a.name == name)
            .map((a) => a.activator());

        return await Promise.all(activators);
    }

    private activators: any[] = [];

    registerService<T>(activator: IServiceActivator<T>): void {
        this.activators.push(activator);
    }

    readonly antiforgeryToken: { 'field': string, 'header': string, 'token': string };

    private isInitialized: boolean = false;

    initialize(): Promise<void> {
        let context = this;
        if (context.isInitialized) {
            console.error('Error - system already initialized');
            return Promise.resolve();
        }

        this.isInitialized = true;

        return context.getServices<IInitializer>('IInitializer')
            .then<{ first: IInitializer[], middle: IInitializer[], last: IInitializer[] }>((initializers) => {
                let sort = (a: IInitializer, b: IInitializer) => { return a.order - b.order; };

                return {
                    first: initializers.filter((i) => i.stage && i.stage < 0).sort(sort),
                    middle: initializers.filter((i) => !i.stage || i.stage == 0).sort(sort),
                    last: initializers.filter((i) => i.stage && i.stage > 0).sort(sort)
                };
            })
            .then(async (items) => {
                if (items.first.length > 0) {
                    await Promise.all(items.first.map((i) => i.initialize(context)));
                }
                return items;
            })
            .then(async (items) => {
                const start = new Date().getTime();

                if (items.middle.length > 0) {
                    await Promise.all(items.middle.map((i) => i.initialize(context)));
                }

                let elapsed = new Date().getTime() - start;
                console.debug('Initializers ran', elapsed);
                return items;
            })
            .then<void>(async (items) => {
                if (items.last.length > 0) {
                    await Promise.all(items.last.map((i) => i.initialize(context)));
                }
            })
            .catch((reason) => {
                console.error(reason);
            });
    }

    registerInitializer(initializer: IInitializer): void {
        let context = this;
        if (context.isInitialized) {
            console.error('Error - cannot register an initializer to a system that is already initialized.');
            return;
        }

        context.registerService({
            name: 'IInitializer',
            activator: () => {
                return Promise.resolve(initializer);
            }
        });
    }

    readonly events: IEventBus = new EventBus();

    readonly profileImagePath: string;
}
