import { IUserContext } from "../IUserContext";
import { UserContext } from "./UserContext";

var getMeta = function (metaName: string): string | null {
    const metas = document.getElementsByTagName('meta');
    for (let i = 0; i < metas.length; i++) {
        if (metas[i].getAttribute('name') === metaName) {
            return metas[i].getAttribute('content') || null;
        }
    }
    return null;
}

var getAntiforgery = function (meta: string | null): { 'field': string, 'header': string, 'token': string } {
    if (meta && meta !== null) {
        var json = atob(meta);
        return JSON.parse(json) as { 'field': string, 'header': string, 'token': string };
    }

    return {
        'field': '',
        'header': '',
        'token': ''
    }
}

var getContextData = function () {
    var contextData = getMeta('context-data');
    if (contextData && contextData !== null)
        return atob(contextData);

    return null;
}

var getContext = function (): IUserContext {
    var contextData = getContextData();
    var hasData = contextData && contextData !== null;

    var antiforgeryMeta = getMeta('antiforgery');
    var antiforgery = getAntiforgery(antiforgeryMeta);

    return hasData
        ? new UserContext(antiforgery, contextData)
        : new UserContext(antiforgery);
}

var context = getContext();

export default context;
