import { IApi } from "../Context/IApi";
import { Api } from "../Context/Api";
import { IApiManager } from "../IApiManager";
import { IUserContext } from "../IUserContext";
import { IApiRequest } from "./IApiRequest";
import { IApiRequestOptions } from "./IApiRequestOptions";

export class ApiManager implements IApiManager {
    constructor(context: IUserContext) {
        this._context = context;
    }

    private readonly _context: IUserContext;

    private getUrl(options: IApiRequestOptions): string {
        var service = this.getService(options.service);
        return service.getUrl(options.path);
    }

    private getService(system: string): IApi {
        var service = this._context.getApi(system);
        if (service) {
            return service;
        }
        return new Api(system);
    }

    private addHeader(headers: { [name: string]: string }, name: string, value?: string | null) {
        if (value && value !== null) {
            headers[name] = value;
        }
    }

    private getCookie(name: string): string | undefined {
        const nameLenPlus = (name.length + 1);
        return document.cookie
            .split(';')
            .map(c => c.trim())
            .filter(cookie => {
                return cookie.substring(0, nameLenPlus) === `${name}=`;
            })
            .map(cookie => {
                return decodeURIComponent(cookie.substring(nameLenPlus));
            })[0] || undefined;
    }

    private getHeaders(options: IApiRequestOptions): { [name: string]: string } {
        var headers = options.headers ?? ({} as { [name: string]: string });
        this.addHeader(headers, 'X-Requested-With', 'XMLHttpRequest');

        if (!options.skipServiceHeaders) {
            if (options.includeAntiforgery) {
                if (this._context.antiforgeryToken.header.length > 0) {
                    this.addHeader(headers,
                        this._context.antiforgeryToken.header,
                        this._context.antiforgeryToken.token ?? '');
                }
            }
            var service = this._context.getApi(options.service);
            if (service) {
                this.addHeader(headers, 'X-TD-GW-SITE', this._context.system);
                this.addHeader(headers, 'Request-Id', this._context.contextId);

                let ctx = this.getCookie('.cd');
                if (ctx) {
                    this.addHeader(headers, 'X-TD-CONTEXT', ctx);
                } else {
                    //legacy cookies
                    if (this._context.settings['ad']) {
                        this.addHeader(headers, 'X-TD-AD', this._context.settings['ad']);
                    }
                    if (this._context.settings['session']) {
                        this.addHeader(headers, 'X-TD-SESSION', this._context.settings['session']);
                    }
                    if (this._context.settings['referrer']) {
                        this.addHeader(headers, 'Referer', this._context.settings['referrer']);
                    }
                    if (this._context.settings['address']) {
                        this.addHeader(headers, 'X-TD-ADDRESS', this._context.settings['address']);
                    }
                    if (this._context.getClaimValue('apikey')) {
                        this.addHeader(headers, 'X-TD-APIKEY', this._context.getClaimValue('apikey'));
                    }
                }

                if (this._context.authToken
                    && this._context.authToken !== null
                    && this._context.authToken.length > 0) {
                    this.addHeader(headers, 'Authorization', 'Bearer ' + this._context.authToken);
                }
            }
        }

        return headers;
    }

    createRequest<T>(options: IApiRequestOptions, content?: T | string, contentType?: string): IApiRequest {
        let request = {
            url: this.getUrl(options),
            method: options.method,
            headers: this.getHeaders(options)
        } as IApiRequest;

        if (content) {
            if (typeof content === 'string') {
                request.content = content;
                if (contentType) {
                    request.headers['Content-Type'] = contentType;
                } else {
                    request.headers['Content-Type'] = 'text/plain';
                }
            } else {
                request.content = JSON.stringify(content);
                request.headers['Content-Type'] = 'application/json';
            }
        }

        return request;
    }

    send(request: IApiRequest): Promise<Response> {
        return fetch(request.url, {
            method: request.method,
            headers: request.headers,
            body: request.content
        });
    }

    get<T>(request: IApiRequest): Promise<T> {
        return this.send(request)
            .then(res => {
                if (!res.ok) {
                    let msg = 'Request to ' + request.url + ' failed: (' + res.status + ') ' + res.statusText;
                    throw new Error(msg);
                }

                return res.json() as Promise<T>;
            });
    }

    getString(request: IApiRequest): Promise<string> {
        return this.send(request)
            .then(res => {
                if (!res.ok) {
                    let msg = 'Request to ' + request.url + ' failed: (' + res.status + ') ' + res.statusText;
                    throw new Error(msg);
                }

                return res.text();
            });
    }
}
