export enum AppType {
    Browser = 0,
    Commands = 1,
    IOS = 2,
    Android = 3,
    Windows = 4,
    Mac = 5,
    Web = 6,
    Process = 7,
    Api = 8,
    Unknown = 99,
}
