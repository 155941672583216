import { default as context } from './Context/ContextInstance';

import { IApi } from "./Context/IApi";
import { IUserContext } from "./IUserContext";
import { ApiManager } from './ApiManager/ApiManager';
import { IServiceActivator } from './Context/IServiceActivator';
import { IApiManager } from './IApiManager';
import { default as register } from './register';

//register api manager

context.registerService({
    name: 'IApiManager',
    activator: () => {
        return Promise.resolve(context.apiManager);
    }
} as IServiceActivator<IApiManager>);

register(context);

export type { ApiResultModel } from './ApiResultModel';
export type { DataModel } from './DataModel';
export type { IApiManager };
export type { IUserContext };
export type { IInitializer } from './IInitializer';

export type { IHistoryManager } from './AdminHistory/IHistoryManager';
export type { IHistoryModel } from './AdminHistory/IHistoryModel';

export type { IAdvertProvider } from './Adverts/IAdvertProvider';

export type { AnalyticModel } from './Analytics/AnalyticModel';
export type { DimensionModel } from './Analytics/DimensionModel';
export type { FactModel } from './Analytics/FactModel';
export type { IAnalyticProcessor } from './Analytics/IAnalyticProcessor';

export { HttpMethod } from './ApiManager/HttpMethod';
export type { IApiRequest } from './ApiManager/IApiRequest';
export type { IApiRequestOptions } from './ApiManager/IApiRequestOptions';

export { AddressStatus } from './Authentication/AddressStatus';
export type { IAddressValidator } from './Authentication/IAddressValidator';
export type { IApiKeyValidator } from './Authentication/IApiKeyValidator';
export type { IAuthenticator } from './Authentication/IAuthenticator';
export type { ILoginResult } from './Authentication/ILoginResult';
export type { LoginResultClaimsModel } from './Authentication/LoginResultClaimsModel';
export type { LoginWithJWTModel } from './Authentication/LoginWithJWTModel';
export type { LoginWithOauthModel } from './Authentication/LoginWithOauthModel';
export type { LoginWithPasswordModel } from './Authentication/LoginWithPasswordModel';
export type { LoginWithUsernameModel } from './Authentication/LoginWithUsernameModel';
export type { LoginWithValidationTokenModel } from './Authentication/LoginWithValidationTokenModel';
export type { RefreshTokenModel } from './Authentication/RefreshTokenModel';
export type { TokenResultModel } from './Authentication/TokenResultModel';
export type { UserInfoModel } from './Authentication/UserInfoModel';
export type { ValidationResultModel } from './Authentication/ValidationResultModel';

export type { EmailAddress } from './Communications/EmailAddress';
export type { EmailAttachment } from './Communications/EmailAttachment';
export type { EmailMessageModel } from './Communications/EmailMessageModel';
export type { EmailSendOptions } from './Communications/EmailSendOptions';
export type { IMessageSender } from './Communications/IMessageSender';
export type { SMSMediaUrl } from './Communications/SMSMediaURL';
export type { SMSMessageModel } from './Communications/SMSMessageModel';
export type { SMSSendOptions } from './Communications/SMSSendOptions';

export type { IApi };
export type { IClaim } from './Context/IClaim';
export type { IEventBus } from './Context/IEventBus';
export type { IServiceActivator };
export type { IUserInfo } from './Context/IUserInfo';

export type { DataProtectionElementsModel } from './DataProtection/DataProtectionElementsModel';
export type { DataProtectionXelementModel } from './DataProtection/DataProtectionXelementModel';
export type { IDataProtectionService } from './DataProtection/IDataProtectionService';

export type { EventModel } from './Events/EventModel';
export type { IEventTrigger } from './Events/IEventTrigger';

export type { IGeocoder } from './Geocoding/IGeocoder';
export type { Coordinate } from './Geocoding/Coordinate';
export type { FieldScore } from './Geocoding/FieldScore';
export type { GeocodeAddress } from './Geocoding/GeocodeAddress';
export type { GeocodeOptions } from './Geocoding/GeocodeOptions';
export type { GeocodeResult } from './Geocoding/GeocodeResult';
export type { GeocodeScore } from './Geocoding/GeocodeScore';
export type { ReverseGeocodeOptions } from './Geocoding/ReverseGeocodeOptions';
export type { Timezone } from './Geocoding/Timezone';

export { Constants } from './Global/constants';

export { AppType } from './Logging/AppType';
export { DeviceType } from './Logging/DeviceType';
export type { IDataLogger } from './Logging/IDataLogger';
export type { LogEntryEventIdModel } from './Logging/LogEntryEventIdModel';
export type { LogEntryExceptionModel } from './Logging/LogEntryExceptionModel';
export type { LogEntryModel } from './Logging/LogEntryModel';
export { LogLevel } from './Logging/LogLevel';
export type { LogStatusModel } from './Logging/LogStatusModel';
export type { SystemInfoModel } from './Logging/SystemInfoModel';
export type { UserContextClientModel } from './Logging/UserContextClientModel';
export type { UserContextModel } from './Logging/UserContextModel';
export type { UserContextSystemModel } from './Logging/UserContextSystemModel';
export type { UserContextUserModel } from './Logging/UserContextUserModel';

export type { CsrModel } from './Lookup/CsrModel';
export type { ILookupService } from './Lookup/ILookupService';
export type { RegionModel } from './Lookup/RegionModel';
export type { ServiceLookupModel } from './Lookup/ServiceLookupModel';
export type { ServiceModel } from './Lookup/ServiceModel';
export { ServiceStatus } from './Lookup/ServiceStatus';
export { ServiceType } from './Lookup/ServiceType';

export type { GetRelationshipsRequest } from './Relationships/GetRelationshipsRequest';
export type { IRelationshipService } from './Relationships/IRelationshipService';
export type { RelationshipModel } from './Relationships/RelationshipModel';
export type { RelationshipResult } from './Relationships/RelationshipResult';
export type { RelationshipSearchObjectsResponse } from './Relationships/RelationshipSearchObjectsResponse';
export type { RelationshipSearchResponse } from './Relationships/RelationshipSearchResponse';
export { RelationshipType } from './Relationships/RelationshipType';
export type { SearchRelationshipsRequest } from './Relationships/SearchRelationshipsRequest';

export type { ISequencer } from './Sequences/ISequencer';
export type { SequenceModel } from './Sequences/SequenceModel';

export type { ISessionProvider } from './Sessions/ISessionProvider';

export type { CreateTaskModel } from './Tasks/CreateTaskModel';
export type { ITaskCreator } from './Tasks/ITaskCreator';
export type { TaskContactModel } from './Tasks/TaskContactModel';
export type { TaskModel } from './Tasks/TaskModel';
export type { TaskOptionsModel } from './Tasks/TaskOptionsModel';

export type { CaptchaResponse } from './Throttling/CaptchaResponse';
export type { ICaptchaService } from './Throttling/ICaptchaService';
export { ClientRestrictionStatus } from './Throttling/ClientRestrictionStatus';
export type { ICountryLookup } from './Throttling/ICountryLookup';
export type { IRestrictionManager } from './Throttling/IRestrictionManager';
export type { IThrottler } from './Throttling/IThrottler';
export type { ThrottleResponse } from './Throttling/ThrottleResponse';
export { ThrottleResult } from './Throttling/ThrottleResult';
export type { UpdateStatusOptions } from './Throttling/UpdateStatusOptions';

export default context;